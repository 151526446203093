.@{select-prefix-cls} {
  &-multiple {
    .@{select-prefix-cls}-selection-item {
      border: none;
      border-radius: @border-radius-sm;
      padding-inline-end: @padding-xs;

      &-remove {

        > .@{iconfont-css-prefix} {
          vertical-align: middle;
        }
      }
    }
  }
}

