@import '../../../../@wieldy/styles/abstracts/variables.less';

@menu-prefix-cls: ~'@{ant-prefix}-menu';

.app-sidebar {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  & .app-scrollbar {
    max-height: calc(100% - @sidebar-header-height);
  }

  &.@{ant-prefix}-layout-sider-collapsed {
    & .app-scrollbar {
      max-height: calc(100% - 118px);
    }
    
    & + .app-main,
    & + .app-main > .app-header {
      margin-left: 70px;
    }
  }

  &:not(.@{ant-prefix}-layout-sider-collapsed) {
    & + .app-main,
    & + .app-main > .app-header {
      @media screen and (min-width: @screen-xl) {
        margin-left: 238px;
      }
    }
  }
}

// Sidebar Menu
.app-sidebar-menu {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 15px;

  & .@{menu-prefix-cls} {
    &-item,
    &-submenu-title {
      margin-left: 13px !important;
      margin-right: 13px;
      border-radius: 4px;

      .@{menu-prefix-cls}-item-icon,
      .@{iconfont-css-prefix} {
        min-width: 20px;
        font-size: 20px !important;
        line-height: inherit !important;

        & > svg {
          margin-top: -4px;
        }
      }
    }

    &-title-content {
      display: flex;
      align-items: center;
      height: @menu-item-height;
      line-height: @menu-item-height;

      & > a {
        display: flex;
        align-items: center;
        flex: 1;
        height: @menu-item-height;
        line-height: @menu-item-height;
      }
    }

    &-item-divider {
      margin-top: 12px;
      margin-bottom: 12px;
      border-color: @sidebar-divider-color;
    }
  }

  &.@{menu-prefix-cls}-inline-collapsed {
    > .@{menu-prefix-cls}-item,
    > .@{menu-prefix-cls}-item-group,
      > .@{menu-prefix-cls}-item-group-list,
      > .@{menu-prefix-cls}-item,
    > .@{menu-prefix-cls}-item-group,
      > .@{menu-prefix-cls}-item-group-list,
      > .@{menu-prefix-cls}-submenu,
      > .@{menu-prefix-cls}-submenu-title,
    > .@{menu-prefix-cls}-submenu > .@{menu-prefix-cls}-submenu-title {
      padding: 0 ~'calc((70px - 36px) / 2)';
    }


    & .@{menu-prefix-cls} {
      &-item,
      &-submenu-title {
        margin-left: 8px !important;
        margin-right: 8px;
        border-radius: 8px;
      }
    }
  }
}

// Sidebar Header

.sidebar-header {
  &-container {
    background-color: @sidebar-header-bg;
    display: flex;
    align-items: center;
    padding: 15px 15px 10px;
    min-height: @sidebar-header-height;

    .ant-layout-sider-collapsed & {
      flex-direction: column;

      & .ant-btn {
        margin-top: 20px;
      }
    }

    & .ant-btn {
      color: #adacbe;
      margin-top: -6px;
    }
  }

  &-logo {
    flex: 1;

    & .brand-name {
      font-size: 20px;
      font-weight: 700;
      color: @sidebar-header-color;
      transition: all 0.2s;
    }

    & .brand-name:not(:first-child) {
      margin-left: 6px;
    }

    & .brand-logo {
      display: flex;
      align-items: center;
    }
  }
}
