
@import '../../../../../@wieldy/styles/abstracts/variables.less';

.header-search {
  width: max-content;
  display: flex;
  //padding: 4px;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  transition: 0.3s ease-in-out;
  justify-content: flex-start!important;
  &:focus-within{
    border-color: #427df0;
    box-shadow: 0 0 0 2px rgba(66, 125, 240, 0.2);
  }
  &:hover {
    border-color: #427df0;
  }
  & .ant-select {
    font-weight: @font-weight-semi-bold;
    font-size: @font-size-sm;
  }

  & .ant-select-selection-search-input {
    font-weight: @font-weight-medium;
    color: @text-color;
  }

  & .select-categories {
    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: none!important;
      }
    }
    .ant-select-selector {
      width: 122px;
      border: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:focus,&:focus-visible {
        border: none;
        outline: none;
        box-shadow: none;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        bottom: 8px;
        width: @border-width-base;
        background-color: @border-color-split;
      }
    }
  }

  & .select-search {
    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: none!important;
      }
    }
    .ant-select-selector {
      border: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:focus,&:focus-visible {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }

  & .ant-select-arrow {
    color: inherit;
  }

  & .ant-select-show-search {
    width: calc(100% - 65px);
    max-width: 320px;
    padding-left: 30px;

    .ant-select-arrow {
      width: fit-content;
      top: 8px;
      bottom: 8px;
      height: auto;
      margin: 0;
      padding-right: 8px;
      left: 8px;
      border-right: @border-style-base @border-color-split @border-width-base;
    }
  }

  & .ant-select-selection-item {
    font-weight: @font-weight-medium;
  }

  & .select-search {
    & .ant-select-item {
      padding: 12px 16px 16px !important;
    }
  }
}

.search-global {
  & .search-header {
    font-size: 13px;
    font-weight: @font-weight-semi-bold;
    padding: @padding-xs @padding-md;
    border-bottom: @border-style-base @border-color-split @border-width-base;
  }

  & .search-footer {
    border-top: @border-style-base @border-color-split @border-width-base;
    padding: 2px 2px 0;
  }

  & .ant-select-item {
    padding: @padding-md;

    &:not(:last-child) {
      border-bottom: @border-style-base @border-color-split @border-width-base;
    }
  }

  & .select-item-title {
    font-weight: @font-weight-semi-bold;
    color: @text-color-secondary;
    text-transform: uppercase;
    font-size: @font-size-xs;
  }
}

.ant-select-dropdown.select-categories {
  min-width: 100px !important;
}
