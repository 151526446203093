
.chatContainer {
  display: flex;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.channelList {
  height: 100%;
  width: 320px;
  flex-shrink: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.chatChannel {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px #0000000f,0 2px 30px #0000001a;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
}

@media (max-width: 768px) {
  .chatChannel {
    border-radius: 10px;
  }
}


.str-chat-channel {
  border-radius: 6px;
}

.str-chat__date-separator {
  padding-left: 0;
  padding-right: 0;
}

.str-chat__avatar-image {
  background-color: transparent !important;
}


.str-chat__message--me {
  justify-items: start !important;
}


.str-chat__message-actions-box--open {
  & + .str-chat__message-actions-box-button {
    visibility: visible;
    background-color: #D8D8D8;
  }
}

.str-chat__message-simple__actions__action {
  position: relative;
}

.str-chat__message--deleted {
  margin-bottom: 8px !important;
}