@import "../WorkspaceHeader";
@import "../utils";

.team-channel-header__container {
  @include workspace-header-layout;
  padding: 10px 20px;
  height: auto;
  flex: none;
  justify-content: flex-start;
}

.team-channel-header__name {
  @include ellipsis-text;
}

.team-channel-header__name.user {
  font: var(--font-weight-medium);
  font-size: 14px;
  margin-bottom: 0;
}

