.ant-table-cell {
  &:not(:first-child) {
    text-align: center;
  }

  &.with-value {
    cursor: pointer;

    &:hover {
      display: block;
      background-color: #D2E2FF !important;
      color: #427DF0 !important;
    }
  }
}

.table-header-last {
  opacity: 0.6;
}