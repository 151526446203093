// --------------------------------------------------------------------
// This file contains all styles related to the page header component.
// --------------------------------------------------------------------

.@{pageheader-prefix-cls} {
  box-shadow: @box-shadow-base;
  padding: @padding-lg;

  @media (min-width: @screen-md-min) {
    padding: @padding-xl;
  }

  &-content {
    @media (max-width: @screen-md-max) {
      padding-top: 0;
    }
  }

  &-footer {
    .@{ant-prefix}-tabs {

      .@{ant-prefix}-tabs-tab {
        font-size: @font-size-base;
        padding: @padding-md;

        & + .@{ant-prefix}-tabs-tab {
          margin-left: 5px;
        }
      }
    }
  }

  &.page-header-offset {
    margin-bottom: -92px;
    padding-bottom: 80px;

    @media (max-width: @screen-sm-max) {
      margin-bottom: -80px;
    }
  }
}

.tabs-container {
  display: flex;
  flex-direction: column;
  min-width: 0;

  & > .@{tab-prefix-cls} {
    flex: 1;
  }

  @media (min-width: @screen-md-min) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .tabs-extra {
    margin-left: @margin-md;

    @media (min-width: @screen-md-min) {
       display: flex;
       //min-width: 22%;
       align-items: center;
       justify-content: flex-end;
    }

    @media (max-width: @screen-sm-max) {
      margin: @margin-lg 0 @margin-lg;
    }

    .has-tabs-secondary & {
      margin:  0;
    }

    .ant-page-header-ghost & {
      margin-bottom: 0;
    }
  }

}

.page-header-secondary {
  display: flex;
  min-width: 0;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-header-button-group {
  margin-bottom: 12px;

  .ant-divider-vertical {
    margin: 0 24px;
  }
}

.page-header-info  {
  @media (min-width: @screen-lg-min) {
    display: flex;
    min-width: 0;
    justify-content: space-between;
    align-items: flex-end;
  }

  &-content {
    flex: 1;
  }
  &-extra {
    margin-top: @margin-lg;
    //width: 100%;
    @media (min-width: @screen-lg-min) {
      margin-top: 0;
      margin-left: @margin-lg;
    }
  }

  .ant-avatar {
    border: 1px solid #bcbaba;
  }
}

.page-header-info-upload-avatar {
  .ant-upload {
    border-radius: 50%;
    background: #f5f4f4;
    position: absolute;
    left: 22px;
    top: 22px;
  }

  .ant-btn {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 }

.list-info {
  display: flex;
  min-width: 0;
  margin-left: -30px;
  margin-right: -30px;
  line-height: 1.5;
  font-weight: @font-weight-semi-bold;

  @media (max-width: @screen-lg-min) {
    margin: 0;
  }



  &-item {
    padding: 6px 15px;
    text-align: center;

    @media (min-width: @screen-lg-min) {
      &:not(:last-child) {
        border-right: 1px solid @border-color-split;
      }
    }

    @media (max-width: @screen-md-max) {
      padding: 8px 12px;
      background-color: #E8EEF6;
      border-radius: @border-radius-sm;

      &:not(:first-child) {
        margin-left: @margin-sm;
      }
    }
  }

  .info-count {
    display: block;
    font-size: 18px;
    color: @heading-color;
    margin-bottom: @margin-xs;
  }
}

.page-header-paper {
  @media (max-width: @screen-xs-max) {
      .paper-sm {
        font-size: 11px;
      }

      .mx-4 {
         margin-left: 8px !important;
         margin-right: 8px !important;
      }
  }
}

.ant-page-header-ghost .ant-tabs:not(.tabs-secondary) {
    //margin-left: -15px;
   .ant-tabs-tab:first-child {
      padding-left: 0;
   }

   .ant-tabs-ink-bar {
     display: none;
   }
}
