.table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: @margin-sm;

  td {
    padding: @padding-xs;
    vertical-align: top;
  }

  &-xs {
    td {
      padding: 2px @padding-xs;
    }
  }

  &-sm {
    td {
      padding: 6px @padding-xs;
    }
  }

  &-lg {
    td {
      padding: @padding-md;
    }
  }
}

.table-bordered {
   tr > td {
     border-bottom: solid 1px @border-color-split;
   }
}

.@{table-prefix-cls} {
  font-size: 12px;
  line-height: 1.8;

  &-thead > tr > th {
    color: #7D7D7D;
    font-weight: @font-weight-semi-bold;
    font-size: @font-size-xs;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: bottom;
    border-radius: 0 !important;
  }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  white-space: nowrap;
}

.expenses-total-cell {
  position: relative;

  &::before {
    position: absolute;
    display: inline-block;
    content: '';
    border-bottom: 1px solid #FFF;
    left: 0;
    width: 30%;
    bottom: -1px;
  }
}

.table-layout-auto {
  table-layout: auto;
  table {
    table-layout: auto;
  }
}

.column-valign-top {
   vertical-align: middle;

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    vertical-align: middle;
  }
}

.ant-table-multi-row {
  &.ant-table-cell-border-none .ant-table-cell {
    border-bottom: none;
  }
}

.ant-table-summary > tr > th, .ant-table-summary > tr > td {
  border: none!important;
}

#individual-time-entries,
.individual-expense-entries {
  .ant-table-tbody > tr > td:last-of-type {
    white-space: break-spaces !important;
  }
}
