#pdf-toggle-pagination {
  display: none;
}
#pdf-pagination {
  margin-right: auto;
}
#header-bar {
  z-index: 0;
}
#pdf-controls {
  z-index: 0;
  box-shadow: none;
}

.doc-viewer-icon {
  color: white !important;
}
