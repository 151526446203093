@import '../../../@wieldy/styles/abstracts/variables.less';

.app-main {
  //background-color: @body-background;
  transition: margin-left 0.3s;
  margin-top: @layout-header-height;
}

.ant-menu-inline-collapsed {
  &-tooltip {
    svg {
      display: none;
    }
  }
}
