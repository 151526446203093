// --------------------------------------------------------------
// This file contains all styles related to the card component.
// --------------------------------------------------------------

.@{card-prefix-cls} {
  box-shadow: @card-shadow;

  &-head {
    font-weight: @font-weight-semi-bold;

    .@{tab-prefix-cls}-top {
       margin-top: -17px;

      .@{tab-prefix-cls}-nav {
        margin-bottom:  0;

        &:before {
          border-bottom: none;
        }
      }
    }

    .card-head-wrap-sm &-wrapper {
      @media (max-width: @screen-xs-max) {
         flex-wrap: wrap;

        .ant-card-head-title {
            width: 100%;
            flex: 0 1 auto;
         }
         .ant-card-extra {
             margin-left: 0;
             padding-top: 0;
         }
      }
    }
  }

  &-meta {
    &-title {
      font-weight: 600;
      font-size: 14px;

      .card-meta-root & {
        margin-bottom: 0 !important;
      }
    }

    &-detail {
      flex: 1;
      overflow: visible;
    }
  }

  &-meta.card-meta-root.d-flex {

    .@{card-prefix-cls}-meta-detail {
      flex: 1;
    }
  }

  &-meta { margin: 0}

  &.card-header-small {
    .ant-card-head {
      min-height: 24px;
      padding: 0 @padding-sm;
    }
    .ant-card-extra,
    .ant-card-head-title {
        padding: 4px 0;
    }
  }
}

.paper {
  display: flex;
  flex-direction: column;
  background-color: #E8EEF6;
  border-radius: @border-radius-base;

  &-body {
    padding: @padding-md;
  }

  &-sm {
    border-radius: 4px;

    .paper-body {
      padding: @padding-sm;
    }
  }
}

.card-supplier {
  display: flex;
  min-width: 0;
  flex-shrink: 0;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;

  @media (min-width: @screen-sm-min) {
     display: inline-flex;
     min-width: 420px;
  }

  @media (max-width: @screen-xs-max) {
     flex: 1;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }

  .ant-table-tbody > tr > td {
     padding-top: 12px;
     padding-bottom: 12px;
  }

  .ant-card-actions > li {
    margin: 6px 0;
    text-align: left;
  }
}

.cols-divider-vertical {
  .ant-col:not(:last-child) {
    border-right: @border-width-base @border-style-base @border-color-split;
  }
}

.dashboard-card {
  @media (min-width: @screen-xxl-min) {
    h5.ant-typography br {
      display: none;
    }
  }
}
