.sidebar {
  padding: 0 30px 0 0;
  aside {
    margin: 0 0 50px;
    ul {
      list-style: none;
      padding-left: 0;
    }
    ul li {
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(238, 238, 238, 0.9);
      a {
        width: 100%;
        padding-bottom: 10px;
        margin-right: 5px;
        color: #999;
        &.active {
          color: #cf2a27;
        }
      }
    }
  }
}
