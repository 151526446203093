
.alert-default {
  border-radius: @border-radius-base;
  background-color: @text-color;
  padding: @padding-md @padding-lg;
  color: @white;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: @screen-md-max) {
    max-width: 500px;
  }

  .ant-typography {
    color: inherit;
  }
}