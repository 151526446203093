@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixin';

.app-table-container {
  & .app-table-actions {
    & .ant-space-item {
      display: inline-flex;
      align-items: center;
    }
  }

  & .ant-table-selection-column {
    padding-left: 20px;
  }
}
