.absolute {
  position: absolute;
}

.relative {
  position: relative;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}