@import '../../../../@wieldy/styles/abstracts/variables.less';

.site-layout-content {
  position: relative;
  z-index: 1;
  padding: @padding-lg;

  @media (min-width: @screen-md-min) {
    padding: @padding-xl;
  }
}
