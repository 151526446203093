@layer default-chat-sdk {
  @import '~stream-chat-react/dist/scss/v2/index.scss';
}

@import "global_theme_elements";
@import "global_theme_variables";
@import "layout";

@import 'ChannelList';
@import "Channel";
@import "Message";
@import "MessagingSidebar";
@import "TeamChannelHeader";
@import "MessageActions";
@import "MessageInput";
@import "MessageList";
@import "MessageReactions";
@import "Thread";
@import "TypingIndicator";
@import "UserList";
@import "WorkspaceHeader";

*::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #b2b1b5;
  border-radius: 2px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
