#mobile-nav-icon {
  display: none;
}

.headerInner {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  #mobile-nav-icon {
    display: block;
    padding-left: 5px;
  }

  .headerTitle {
    font-size: 16px;
  }
}

