// -------------------------------------------------------------------------------------
// This file contains all styles related to the Section Layout of the site/application.
// -------------------------------------------------------------------------------------


.section {
  &-root + &-root {
    margin-top: @margin-xl;
  }
  &-header {
    display: flex;
    min-width: 0;
    margin-bottom: @margin-lg;
    flex-direction: column;

    @media (min-width: @screen-sm-min) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-title {
    flex: 1;
    position: relative;
    margin-top: @typography-title-margin-bottom;
  }

  &-action {
    margin-left: @margin-md;
    display: flex;
    align-items: center;

    @media (max-width: @screen-xs-max) {
       margin-left: 0;
       margin-top: @margin-md;
    }
  }
}