@import '@wieldy/styles/abstracts/variables.less';

.candidate-details {
  position: relative;

  .ant-tabs-nav {
     margin-bottom: @margin-xl;

    &::before {
      display: none;
    }
  }

  &::after {
    display: inline-block;
    position: absolute;
    content: '';
    left: -24px;
    right: -24px;
    border-bottom: 1px solid #D8D8D8;
    top: 47px;
  }
}