.@{steps-prefix-cls} {
  &-item {
    &-title {
      font-size: @font-size-base + 1px;
      font-weight: @font-weight-semi-bold;
    }
  }

  &-vertical  {
    .@{steps-prefix-cls}-item-container {
      display: flex;
      min-width: 0;
    }
    & > .@{steps-prefix-cls}-item .@{steps-prefix-cls}-item-content {
      overflow: visible;
      flex-grow: 1;
      width: calc(100% - 24px);
    }
    & > .@{steps-prefix-cls}-item .@{steps-prefix-cls}-item-description {
      padding-bottom: @padding-md;

    }

    &.@{steps-prefix-cls}-dot .@{steps-prefix-cls}-item-icon {
       flex-shrink: 0;
    }
  }

}