// -------------------------------------------
// This file contains Typography styles.
// -------------------------------------------

.@{typography-prefix-cls} {
  strong {
    font-weight: 700;
  }
}

h1.@{typography-prefix-cls} {
  line-height: 1.5;

  strong {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.title-secondary {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: @font-size-xs !important;
  position: relative;
  padding-bottom: 4px;

  &::after {
    display: inline-block;
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: @text-color-secondary;
  }
}

.title {
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: @typography-title-margin-bottom;

    &-divider {
      display: flex;
      width: 30%;
      max-width: 200px;
      height: 1px;
      margin-left: @margin-sm;
      background-color: @border-color-split;
    }
}

.sub-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: @font-size-xs !important;
}

.text-color {
  color: @text-color !important;
}

.text-primary {
  color: @primary-color !important;
}

.text-secondary {
  color: @text-color-secondary !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-xs {
  font-size: @font-size-xs !important;
}

.text-sm {
  font-size: @font-size-sm !important;
}

.text-base {
  font-size: @font-size-base !important;
}

.text-lg {
  font-size: @font-size-lg !important;
}

.text-xl {
  font-size: @font-size-xl !important;
}

.text-xxl {
  font-size: @font-size-xxl !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.font-regular {
  font-weight: @font-weight-regular !important
}

.font-medium {
  font-weight: @font-weight-medium !important;
}

.font-semibold {
  font-weight: @font-weight-semi-bold !important;
}

.tracking-normal {
  letter-spacing: 2px;
}