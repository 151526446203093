// ----------------------------------------------------------------
// This file contains all styles related to the Pagination component.
// ----------------------------------------------------------------

.@{pagination-prefix-cls} {
  font-weight: @font-weight-semi-bold;
  font-size: @font-size-sm;

  &-item {
    border-width: 2px;
    border-color: @border-color-dark;
    line-height: @pagination-item-size - 4px;

    &-active {
      border-color: @primary-color;
      background-color: @primary-color;

      &:hover a,
      a {
        color: #FFF;
      }
    }
  }

  &-prev,
  &-next {
    .@{pagination-prefix-cls}-item-link {
      border-width: 2px;
      border-color: @border-color-dark;
    }
  }

  &-simple &-prev,
  &-simple &-next {
    line-height: @height-sm;
    height: @height-sm;
    min-width: @height-sm;

    .@{pagination-prefix-cls}-item-link {
      height: @height-sm;
      border: 2px @border-style-base @border-color-dark;

      &:hover {
        border-color: @primary-color;
      }
    }
  }

  &-simple &-simple-pager {
    height: @height-sm;

    input {
      border: none;
      pointer-events: none;
      margin: 0;
      padding: 0;
      font-weight: @font-weight-semi-bold;
      background-color: transparent;
      max-width: 20px;
    }

    .ant-pagination-slash {
      font-size: 0;
      margin: 0 5px 0 2px;

      &::after {
        font-size: 12px;
        content: 'of';
        display: inline-block;
      }
    }
  }

  &-jump-prev,
  &-jump-next {
    .@{pagination-prefix-cls}-item-container {
      .@{pagination-prefix-cls}-item-ellipsis {
        color: @text-color;
      }
    }
  }

  &.mini &-item {
    margin-right: @margin-xs;
    line-height: 28px;
  }

  &.mini &-item:not(&-item-active) {
    background: transparent;
    border-color: @border-color-dark;
  }

  &.mini &-prev,
  &.mini &-next {
    margin-right: @margin-xs;
  }

  &.mini &-prev &-item-link,
  &.mini &-next &-item-link {
    border-color: @border-color-dark;
  }

  &.mini &-jump-prev,
  &.mini &-jump-next {
    margin-right: @margin-xs;
  }

  .@{select-prefix-cls}:not(.@{select-prefix-cls}-customize-input) .@{select-prefix-cls}-selector {
    border-width: 2px;
    font-size: @font-size-xs;

    .@{select-prefix-cls}-selection-item {
       line-height: 28px;
    }
  }

}

.pagination-mb-0 {
  .ant-table-pagination.@{pagination-prefix-cls} {
      margin-bottom: 0;
  }
}