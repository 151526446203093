.wrapper-read {
  width: 100%;

  .ql-container.ql-snow {
    border: none;
  }

  p {
    margin-bottom: 0;
  }

  .ql-editor {
    padding: 0;
  }
  .ql-editor p {
    font-family: 'Montserrat', sans-serif;
  }
}

.wrapper-editor {
  .ql-editor p {
    font-family: 'Montserrat', sans-serif;
  }
  .ql-toolbar.ql-snow {
    border-color: #6a6a6a;
    border-radius: 8px 8px 0 0;
  }
  .ql-container.ql-snow {
    font-family: 'Montserrat', sans-serif;
    border-color: #6a6a6a;
    border-radius: 0 0 8px 8px;
    min-height: 100px;
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
    font-weight: 300;
  }
  &.error {
    .ql-toolbar.ql-snow {
      border-color: #ff4d4f;
    }
    .ql-container.ql-snow {
      border-color: #ff4d4f;
    }
  }
}

.ant-typography {
  p {
    margin-bottom: 0;
  }
}
