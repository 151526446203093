// ---------------------------------------------------------------
// This file contains all styles related to the tabs component.
// ---------------------------------------------------------------.

.@{tab-prefix-cls} {
  min-width: 0;

  &-top,
  &-bottom {

    > .@{tab-prefix-cls}-nav,
    > div > .@{tab-prefix-cls}-nav {

      .@{tab-prefix-cls}-ink-bar {
        height: 3px;
        border-radius: @border-radius-base;
      }
    }
  }

  &-tab {
    text-transform: uppercase;
    color: @tabs-color;
    letter-spacing: 2px;
    font-weight: @font-weight-semi-bold;

    .tabs-secondary & {
      color: @primary-color;
      font-size: @font-size-base + 1px !important;
      text-transform: none;
      letter-spacing: normal;

      & + .ant-tabs-tab::before {
        display: inline-block;
        position: absolute;
        left: -2px;
        content: '';
        height: 24px;
        width: 1px;
        background-color: @border-color-split;
      }

    }

  }

  &-nav-operations {
    .anticon-ellipsis {
       font-size: @font-size-xxl;
    }
  }

  &-dropdown-menu-item {
    font-weight: @font-weight-medium;
  }

}

.tabs-extra .tabs-secondary .ant-tabs-nav-operations {
   display: none;
}

.card-extra-tabs  {
  .ant-card-head-title {
    overflow: visible;
    margin-right: 30px;
  }

  .ant-card-extra {
    overflow: hidden;
  }

}