// ---------------------------------------------------------------
// This file contains all styles related to the Buttons component.
// ---------------------------------------------------------------

.btn-ribbon {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #CDCDCD;
  border-radius: 0 @card-radius 0 @card-radius;
  width: 48px;
  height: 36px;

  & > svg {
    margin-top: -2px;
  }

  &:hover, &:focus {
    background-color: #CDCDCD;
  }

  &.bookmarked {
    background-color: #DA4A4A;
    color: #FFE896;
  }
}

.@{btn-prefix-cls} {
  &-round&-sm {
    font-size: @font-size-xs;
  }

  &-round&-xs {
    padding: 0 @padding-sm;
    height: 28px;
  }

  & > svg + span {
    vertical-align: middle;
  }

  &.bookmarked {
    background-color: #DA4A4A;
    color: #FFE896;
  }
  &.not-bookmarked {
    background-color: #CDCDCD;
  }
}

.btn-success {
  background-color: @success-color;
  border-color: @success-color;

  &:hover {
    background-color: @success-hover-color;
    border-color: @success-hover-color;
  }

  &:focus {
    background-color: @success-hover-color;
    border-color: @success-hover-color;
  }

  &:active {
    background-color: @success-active-color;
    border-color: @success-active-color;
  }
}