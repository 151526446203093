.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  padding: 0 4px;
  height: 100%;
}

.str-chat__channel-list {
  padding: 10px 20px 20px;
  border-inline-end: 0;
  background-color: transparent;
}

.str-chat__channel-list-messenger__main {
  display: grid;
  grid-row-gap: 10px;
  grid-auto-rows: max-content;
}