.container {
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
}
.wrapper {
  background: #fff;
}

.header {
  z-index: 10;
  background: #fff;
  padding: 16px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .container();
  }
}

.title {
  position: sticky;
  top: 0;
  background-color: #f7f7f7;
  border-bottom: 1px solid #f2f2f2;
  padding: 112px 0 30px;
  z-index: 1;
  &__container {
    .container();
  }
  &__text {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 15px;
    margin: 0;
  }
}
.module {
  padding-top: 100px;
  padding-bottom: 200px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  &__menu {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    @media (max-width: 992px) {
      flex: 0 0 100%;
      max-width: 100%;
      order: 1;
    }
  }
  &__value {
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
    @media (max-width: 992px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.accept {
  background-color: #f7f7f7;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  &__container {
    .container();
    display: flex;
    gap: 20px;
  }
}
