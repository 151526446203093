.upload-preview {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;
  gap: 8px;
  &__actions {
    display: flex;
    align-items: center;
    gap: 8px
  }
  &__image {
    width: 50px;
    height: 50px;
  }
}

