@import '../../../../../@wieldy/styles/abstracts/variables.less';

.notification-popover {
  min-width: 300px;

  & .ant-popover-title {
    font-size: 14px;
    font-weight: 600;
    padding: @padding-sm @padding-md;
  }

  & .ant-popover-inner-content {
    padding: 0;
    width: 100%;

    & .app-scrollbar {
      max-height: 292px;
      overflow-y: auto;
    }
  }

  & .notification-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 2px 0;
    border-top: @border-style-base @border-color-split @border-width-base;
    background-color: @popover-bg;
    border-bottom-left-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
  }

  & .ant-list-item {
    padding: @padding-md;

    & .ant-list-item-meta-title {
      line-height: 18px;
      font-weight: 500;
    }

    
    & .avatar {
      background-color: #E9E9E9;
      border-radius: 6px;
      color: #535353;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: @item-hover-bg;
      cursor: pointer;

      & .ant-list-item-meta-title {
        color: @primary-color;
      }

      & .avatar {
        background-color: fade(@primary-color, 20%);
        color: @primary-color;
      }
    }

  }
}


