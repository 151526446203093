@import '../../../../@wieldy/styles/abstracts/variables.less';

.app-header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  transition: margin-left 0.3s;
  border-bottom: solid 1px @border-color-split;
  padding: 10px @padding-lg;

  @media (min-width: @screen-md-min) {
    padding: 10px @padding-xl;
  }

  .header-search {
    display: flex;
    align-items: center;
    line-height: 1.5;
    justify-content: space-between;
    flex: 1;
    max-width: 390px;

    @media (max-width: @screen-md-min) {
      position: absolute;
      left: 0;
      right: 0;
      max-width: 100%;
      width: 100%;
      z-index: 5;
      transition: 0.3s ease 0s;
      transform: translateY(-65px);
      padding: 10px @padding-lg;
      background: @layout-header-background;
      visibility: hidden;
      border: none!important;
      &.header-search:focus-within {
        background-color: white !important;
        box-shadow: none !important;
        border: none!important;
      }
      &.active {
        transform: translateY(0);
        visibility: visible;
      }
    }

    .search-close {
      display: none;
      margin-right: -12px;

      @media (max-width: @screen-md-min) {
        display: inline-block;
      }
    }
  }

  .brand-logo {
    display: flex;
    align-items: center;
    min-width: 0;
    line-height: 36px;
    margin-left: 10px;

    .site-name {
      margin-left: 5px;
      font-size: 20px;
      font-weight: @font-weight-bold;
    }
  }
}

.nav {
  &-vertical {
    color: @text-color-secondary;
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    line-height: 1.5;

    .ant-badge {
      color: inherit;
    }
  }
  &-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: @margin-md;
    }

    &-search {
      display: none;
      @media screen and (max-width: @screen-md-min) {
        display: flex;
      }
    }
  }
}
