@import '../../../../@wieldy/styles/abstracts/variables';
@import '../../../../@wieldy/styles/abstracts/mixin';

.PhoneInput {
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 7px 14px;
  color: #494949;
  font-size: 13px;
  line-height: 24px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #6A6A6A;
  border-radius: 8px;

  &:hover {
    border-color: #6fa3fc;
  }

  &--focus {
    box-shadow: 0 0 0 2px rgba(66, 125, 240, 0.2);
  }

  &CountrySelectArrow {
    height: 5px;
    width: 5px;
    opacity: 1;
    color: #494949 !important
  }

  &Input {
    border: none;
    outline: none;
  }
}