
.@{card-prefix-cls}.dashboard-card {
    .@{card-prefix-cls}-head-title {
        font-size: 20px;
        color: @heading-color;
    }

  .@{card-prefix-cls}-extra {
      color: #B3CBF9;
      padding: 12px 0;
  }

  .@{card-prefix-cls}-body {
     padding-bottom: 18px;
  }

}