@import '../../../../../@wieldy/styles/abstracts/variables.less';

.user-popover {
  .ant-list-item {
    cursor: pointer;
    font-weight: @font-weight-semi-bold;
    color: @primary-color;
    padding: @padding-xs 0;

    &:hover {
      color: @text-color-secondary;
    }
    &.disabled {
      cursor: initial;
      opacity: 0.5;

      &:hover {
        color: @primary-color;
      }
    }
    .ant-list-item-meta-content {
      color: inherit;
    }
  }
}

.user-root {
  display: flex;
  min-width: 0;
  align-items: center;
  padding-right: @padding-md;

  .ant-avatar {
    margin-right: @margin-md;
  }

  .user-detail {
    flex: 1;
  }
}
