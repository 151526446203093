
.auth-container {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-width: 0;

  .site-layout-content {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-width: 0;
  }
}

.login {
  &-root {
    margin: auto;
    width: 980px;
    max-width: 100%;
    @media (max-width: @screen-sm-max) {
      width: 440px;
    }

    .brand-logo {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: @font-weight-bold;
      margin-bottom: 32px;

      .site-name {
        color: @text-color
      }
    }
  }

  &-container {
    display: flex;
    min-width: 0;
    min-height: 380px;
  }

  &-content {
    flex: 1;
    padding: @padding-xl;
    @media (min-width: @screen-md) {
      flex: 0 0 45%;

      form {
        max-width: 320px;
      }
    }
  }

  &-extra {
    flex: 0 0 55%;
    display: flex;
    padding: @padding-lg;
    align-items: center;
    justify-content: center;
    background-color: #E8EEF6;

    img {
      max-width: 100%;
    }

    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }
}
