@import "../../../@wieldy/styles/abstracts/variables";

.carousel {
  &-root {
    margin: 0 -@margin-lg @margin-lg;
    background-color: #E8EEF6;
    padding: @padding-md (@padding-lg * 2);
    position: relative;

    .ant-list-item {
      margin-bottom: 0 !important;
    }

    .card-meta-root .ant-list-item-meta-title {
       font-size: @font-size-base;
    }
  }

  &-control-prev,
  &-control-next {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    z-index: 1;
    left: 8px;

    & > svg {
      font-size: 28px;
    }
  }
  &-control-next {
    left: auto;
    right: 8px;
  }
}

.ant-carousel .slick-list .slick-slide > div {
  padding: @padding-sm 0;
}