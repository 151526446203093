.m-0 {
  margin: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}


.m-1 {
  margin: 4px !important;
}

.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.ml-1 {
  margin-left: 4px !important;
}


.m-2 {
  margin: 8px !important;
}

.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.ml-2 {
  margin-left: 8px !important;
}


.m-3 {
  margin: 12px !important;
}

.mx-3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.ml-3 {
  margin-left: 12px !important;
}


.m-4 {
  margin: 16px !important;
}

.mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.ml-4 {
  margin-left: 16px !important;
}


.m-5 {
  margin: 20px !important;
}

.mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.ml-5 {
  margin-left: 20px !important;
}


.mt-6 {
  margin-top: 24px !important;
}

.mt-7 {
  margin-top: 28px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-9 {
  margin-top: 36px !important;
}

.mt-10 {
  margin-top: 40px !important;
}

.mt-11 {
  margin-top: 44px !important;
}

.mt-12 {
  margin-top: 48px !important;
}


.mr-6 {
  margin-right: 24px !important;
}

.mr-7 {
  margin-right: 28px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.mr-9 {
  margin-right: 36px !important;
}

.mr-10 {
  margin-right: 40px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.mb-9 {
  margin-bottom: 36px !important;
}

.mb-10 {
  margin-bottom: 40px !important;
}

.mb-11 {
  margin-bottom: 44px !important;
}

.mb-12 {
  margin-bottom: 48px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}