.messaging__channel-list__header {
  display: flex;
  align-items: center;
  padding: 30px 10px 10px 30px;
}

.messaging__channel-list__header__name {
  display: flex;
  line-height: 20px;
  font-size: 16px;
  margin: 0 10px;
}