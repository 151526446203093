.@{descriptions-prefix-cls} {
  &-item-label {
    color: @text-color-secondary;
    font-weight: @font-weight-medium;
    line-height: 1.8;
    padding-left: @padding-xs;
    padding-right: @padding-xs;

    .description-root & {
      @media (max-width: @screen-sm-max) {
         width: 50% !important;
      }
    }

  }

  &-item-content {
    line-height: 1.8;
    padding-left: @padding-xs;
    padding-right: @padding-xs;
  }

  &-view {
    margin-left: -@margin-xs;
    margin-right: -@margin-xs;
    width: auto;
  }

  &-title {
    font-weight: @font-weight-semi-bold;
    font-size: @heading-4-size;
  }
}

.description-skills {
  .@{descriptions-prefix-cls} {
    &-item {
      &-label {
        @media (max-width: @screen-sm-max) {
          width: auto !important;
          font-weight: @font-weight-semi-bold;
          font-size: 14px;
        }
      }

      &-container {
        @media (max-width: @screen-sm-max) {
          display: block;
        }
      }
    }
  }
}

.description-summary {
  @media (min-width: @screen-md-min) {
    & > .ant-col {
      max-width: 320px;
    }
  }
  .@{descriptions-prefix-cls} {
    &-item-content {
        justify-content: flex-end;
    }
  }
}