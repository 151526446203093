@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixin';

.app-filter-wrapper {
  margin-bottom: 30px;

  & .ant-card-head {
    background-color: #D4E3FF;
    min-height: 0;
    border-bottom: none;

    & .ant-card-head-title, & .ant-card-extra {
      padding: 4px 0;
    }
  }

  & .filter-actions {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    & .ant-btn {
      min-width: 114px;

      &:not(:first-child) {
        margin-right: 12px;
      }
    }
  }
}

