// -------------------------------------------------------------
// This file contains all styles related to the Tag component.
// --------------------------------------------------------------

.@{tag-prefix-cls} {
  font-weight: @font-weight-semi-bold;
  padding: 0 5px;
  text-align: center;

  &-hidden-square {
    text-overflow: ellipsis;
    overflow: hidden;
    pointer-events: none;
  }

  &-circle {
    border-radius: 20px;
    padding: 0 16px;
  }

  &-square {
    border-radius: 4px;
    padding: 0 8px;
    font-weight: @font-weight-medium;
  }

  &-success,
  &-approved {
    color: @success-color;
    border-color: @success-color;
    background-color: fade(@success-color, 18%);
  }

  &-pending {
    color: #7E7C9C;
    border-color: #A9A8B6;
    background-color: #E3E3EC;
  }

  &-blue {
    padding: 0 @padding-sm;
    background-color: transparent;
    color: @primary-color;
    border-color: @primary-color;
  }

  .ant-typography {
    color: inherit;
  }

  &.tracking-normal {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 9px !important;
  }

  &.tracking-small {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 6px !important;
    padding: 0 10px;
  }
}
