// ----------------------------------------------------------------
// This file contains all styles related to the Modal component.
// ----------------------------------------------------------------

.@{dialog-prefix-cls} {

  &-title {
    font-weight: @font-weight-semi-bold;
  }

  &-footer {
     text-align: left;
     padding: (@modal-footer-padding-vertical/2)  @modal-footer-padding-horizontal @modal-footer-padding-vertical;
  }
}

.inquiry-reject-modal .ant-modal-footer{
  display: flex;

  button:nth-child(1) { 
    order: 2;
    margin-left: 8px;
  }
  button:nth-child(2) { 
    order: 1; 
    margin-left: 0 !important;
  }
}

.submission-reject-modal .ant-modal-footer{
  display: flex;

  button:nth-child(1) { 
    order: 2;
    margin-left: 8px;
  }
  button:nth-child(2) { 
    order: 1; 
    margin-left: 0 !important;
  }
}

