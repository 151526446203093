.@{picker-prefix-cls} {
  padding: @input-padding-vertical-base (@input-padding-vertical-base * 2) ;

  &-input {
    font-weight: @font-weight-medium;

    & > input {
      font-weight: inherit;
    }
  }
}

.ant-picker-time-panel-column::after {
  display: none;
}