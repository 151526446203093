.post {
  .post-wrapper {
    padding: 0 30px 40px 30px;
  }
  .post-header {
    margin: 0 0 30px;
  }
  .post-header-button {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .post-title {
    font-size: 30px;
    margin: 0;

    @media (max-width: 992px) {
      font-size: 26px;
      margin: 0 0 15px;
    }
  }
  .post-content {
    p {
      margin: 0 0 30px;
    }
    ul {
      list-style: outside;
      margin-left: 3em;
    }
    ul li {
      margin-bottom: 10px;
      a {
        width: 100%;
        padding-bottom: 10px;
        margin-right: 5px;
        color: #999;
        &.active {
          color: #cf2a27;
        }
      }
    }
  }
  .post-meta {
    list-style: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    color: #bdbdbd;
    @media (max-width: 992px) {
      margin: 0 0 15px;
    }
  }
}
