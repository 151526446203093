@import "../../../@wieldy/styles/abstracts/variables";

.app-reveal-card {
  position: relative;
  overflow: hidden;
  box-shadow: @box-shadow-base;
  border-radius: @card-radius;

  .app-card {
    box-shadow: none;
  }

  .ant-drawer-content-wrapper {
    border-radius: @card-radius;
    overflow: hidden;
    //max-height: calc(100% - 35px) !important;
  }

  & .app-scrollbar {
    height: 100%;
    overflow-x: hidden;
  }

  .ant-drawer-header {
    background-color: #D2D2D2;
    padding: 4px @padding-sm;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }
  .ant-drawer-title {
    font-size: 11px;
    margin-left: 2px;
    font-weight: @font-weight-semi-bold;
  }
  .ant-drawer-close {
    color: @text-color;
    margin: -3px -8px -4px 0;
  }
}

