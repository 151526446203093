.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 4px !important;
}

.p-2 {
  padding: 8px !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.pl-2 {
  padding-left: 16px;
}
.pr-2 {
  padding-right: 16px;
}

.px-2 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.pt-2 {
  padding-top: 16px!important;
}

.pb-10 {
  padding-bottom: 40px !important;
}

