.@{rate-prefix-cls} {
  font-size: 18px;

  &-star {
    &:not(:last-child) {
      margin-right: 3px;
    }
  }
}

