// --------------------------------------------
// This file contains very basic styles.
// --------------------------------------------

body {
  position: relative;
  min-height: 100vh;
  font-weight: @font-weight-medium;
}

#root {
  display: flex;
  min-width: 0;
  flex-direction: column;
  min-height: 100vh;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.h-100 {
  height: 100% !important;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
