.messaging__sidebar {
    width: 330px;
    background-color: #fafafa;
    flex-shrink: 0;
    box-shadow: 0 0 8px #0000000f,0 2px 30px #0000001a;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.str-chat.str-chat__theme-dark .messaging__channel-list__message {
    color: #fff;
}

.messaging__channel-list__header__name {
    font-weight: bold;
    color: var(--str-chat__text-color);
}


.messaging__channel-list__header__button {
    border: 0;
    cursor: pointer;
    background: #fafafa;
    border-radius: 20px;
    box-shadow: var(--str-chat__channel-box-shadow);
}

.str-chat__theme-dark .messaging__channel-list__header__button {
    background: #3e3e41;
    box-shadow: none;
}

.messaging__channel-list__header__button svg path {
    fill: var(--str-chat__primary-color);
}

.str-chat__theme-dark .messaging__channel-list__header__button svg path {
    fill: #e9e9ea;
}

.messaging__channel-list__header__button:focus {
    border: none;
    outline: none;
}

.messaging__channel-list__message {
    color: #000;
}