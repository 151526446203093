.wrapper {
  width: 100%;
}

.channelListItem {
  border: 1px solid #D8D8D8;
  background-color: #FFFFFF;
  cursor: pointer;
  transition: 0.3s ease all;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 8px;
  width: 100%;
  &:hover {
    background-color: #D8D8D8;
  }
}


.staticChannel {
  border-bottom: 1px solid black;
  padding-bottom: 12px;
}

.avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: transparent;
  border-radius: 50%;
}

.preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  gap:2px
}


.channel-preview__container {
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
}

.channel-preview__container:hover {
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.str-chat.str-chat__theme-dark .channel-preview__container:hover {
  background: #2c2c30;
}

.channel-preview__container.selected {
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.str-chat.str-chat__theme-dark .channel-preview__container.selected {
  background: #2c2c30;
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;

}

.channel-preview__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.channel-preview__content-name {
  font-family: Helvetica Neue, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 213px;
}

.str-chat.str-chat__theme-dark .channel-preview__content-name {
  color: #ffffff;
}

.channel-preview__content-time {
  font-family: Helvetica Neue, sans-serif;
  font-size: 11px;
  color: #858688;
  margin: 0;
}

.channel-preview__content-message {
  font-family: Helvetica Neue, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: #858688;
  height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__content-message p {
  margin: 0;
}

.channel-preview__container .str-chat__avatar {
  margin-right: 0;
}
