.str-chat__reaction-selector {
  left: unset;
  top: -60px;
  right: 0;
}

.str-chat__reaction-selector .emoji-mart-emoji span {
  height: 24px !important;
  width: 24px !important;
}
