// ----------------------------------------------------------------
// This file contains all styles related to the Popover component.
// ----------------------------------------------------------------

.@{popover-prefix-cls} {
  font-weight: @font-weight-medium;

  &-inner-content {
    padding: @popover-padding-horizontal;
  }

  &-message > .anticon {
    top: 10px;
  }

  .ant-menu-vertical {
     border-right: none;
     margin: -12px -@popover-padding-horizontal;

    .ant-menu-item {
      height: @height-sm;
      line-height: @height-sm;
      margin-top: 0;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}

