.@{select-prefix-cls} {
  font-weight: @font-weight-medium;

  &-arrow {
    color: inherit;
  }

  &.select-standard {
    font-weight: @font-weight-semi-bold;

    &:not(&-customize-input) .@{select-prefix-cls}-selector {
      border-width: 2px !important;

      .@{select-prefix-cls}-selection-item {
        line-height: 28px;
      }
    }
  }

  &-selection-item {
    font-weight: inherit;
  }

  &-single {
    &.@{select-prefix-cls}-sm {
      font-size: @font-size-xs;
    }
  }
}

.@{form-item-prefix-cls} {

  input[type='radio'],
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
}

.@{ant-prefix}-checkbox {
  &-inner {
    border-color: @border-color-split;
  }
  &-wrapper {
    align-items: flex-start;
  }
}


.@{ant-prefix}-input{
  font-weight: @font-weight-medium;
}

.@{ant-prefix}-select-item {
  font-weight: @font-weight-medium;
}

.@{ant-prefix}-picker-suffix {
  .anticon-calendar {
     color: @primary-color;
  }
}