// --------------------------------------------------------------
// This file contains all styles related to the list component.
// --------------------------------------------------------------


//.view-page {
//  .@{list-prefix-cls} {
//    &-item {
//      &-meta {
//        &-avatar {
//          margin-right: 0;
//
//        }
//      }
//    }
//  }
//}

.@{list-prefix-cls} {
  &-item {
    &-meta {
      &-avatar {
        margin-right: @margin-sm;
        .card-meta-root & {
          display: flex;
          min-width: 0;
          margin-right: @margin-xs;
          color: @text-color-secondary;
        }
      }
      &-title {
        display: flex;
        margin-top: 2px;
        line-height: 1.5;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;

        .supplier-list & {
          margin-top: 4px;
        }

        .card-meta-root & {
          font-size: @font-size-sm;
          margin-bottom: 2px;
          line-height: 1.75;
        }

        .card-meta-root-lg &{
           margin-top: 0;
           font-size: @font-size-base;
        }
      }
    }
  }
}

.list-item-borderless.ant-list-split  {
  .ant-list-item {
    border-bottom: none;
  }
}

.list-menu {

  .ant-list-item {
    cursor: pointer;
    border: none;
    padding: @padding-sm - 2px @padding-md;
    margin-bottom: 2px;

    &:hover,
    &.active {
      border-radius: @border-radius-base;
      background-color: #CFDFFF;
    }
  }

  .ant-list-item-meta-title {
    font-weight: @font-weight-semi-bold;
  }
}

.ant-list-split .ant-list-item.app-border-box:last-child {
   border-bottom: 1px solid @border-color-base;
   margin-bottom: 0 !important;
}

.all-notification {
  .ant-list-item {
    cursor: pointer;
    padding: @padding-md @padding-lg;

    &-meta-avatar {
      margin-right: 18px;
    }

    &-meta-title {
      font-size: 14px;
      margin-bottom: 2px;
    }

    &:hover {
      background-color: @item-hover-bg;

      .ant-list-item-meta-title {
        color: @primary-color;
      }

      .avatar {
        background-color: fade(@primary-color, 20%);
        color: @primary-color;
      }
    }

  }
}

.user-stats {
  display: flex;
  min-width: 0;
  font-size: 12px;
  align-items: center;
  margin: 0 -@margin-md;

  &-item {
    padding: 0 @padding-md;
    &:not(:last-child) {
      border-right: 1px solid @border-color-split;
    }
  }

  .ant-tag {
    line-height: 22px;
  }

  @media (max-width: @screen-sm-max) {
    .user-stats-item {
      min-width: 120px;
      text-align: center;

      .ant-tag {
        margin: 0 0 8px;
      }

      & > .ant-typography {
        display: block;
        line-height: 1.75;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    .user-stats-item {
       min-width: 0;
    }
  }
}

.candidate-stats {
  @media (min-width: @screen-xxl-min) {
    .user-stats-item {
      min-width: 120px;
      text-align: center;
      .ant-tag {
        margin: 0 0 8px;
      }
      & > .ant-typography {
        display: block;
      }
    }
  }
}
