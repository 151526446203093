#supplier-registration {
  .form-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    &.columns-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .form-hint {
    max-width: 330px;
    color: #6b7280; // Tailwind's gray-500 hex code
  }

  .form-box-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 20px; // Tailwind's mb-5 is equivalent to 20px
  }
  .ant-form-item-label {
    & label:after {
      content: '' !important;
    }
  }
  .ant-form-item-label-left {
    & > label {
      width: 200px;
      flex-shrink: 0;
    }
  }
  .form-error-help {
    clear: both;
    color: #cf2a27;
    font-size: 13px;
    line-height: 24px;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .form-children-checkboxes {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
  }
  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
  .ant-form-item-control.with-extra {
    flex-direction: row;
    .ant-form-item-control-input {
      flex-shrink: 0;
      margin-right: 8px;
      margin-bottom: 20px;
    }
  }
  .form-field-required:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 13px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .form-check-area {
    display: flex;
    flex-direction: column;
    max-height: 384px; // Tailwind's max-h-96 is equivalent to 384px
    overflow: auto;
    border: 1px solid #e5e7eb; // Default border color similar to Tailwind
    padding: 20px; // Tailwind's p-5 is equivalent to 20px
    border-radius: 6px; // Tailwind's rounded-md
    &.form-error {
      border-color: #ff4d4f;
    }
  }
}

#supplier-data {
  .ant-checkbox-wrapper {
    margin-left: 0;
  }
}

.form-service-view .ant-checkbox-disabled + span {
  color: inherit !important;
}
